import { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { App as AntApp, ConfigProvider, message } from 'antd';
import { IntercomProvider } from 'react-use-intercom';

import { useApollo } from 'lib/apollo/client';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';
import Providers from 'components/Providers';

import analyticsAdapter from 'utils/analyticsAdapter';

import GlobalStyles from 'styles/global';
import antdTheme from 'styles/antdTheme';

import 'styles/fonts.css';
import 'styles/nprogress.css';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [currentPath, setCurrentPath] = useState(router.asPath);

  useEffect(() => {
    const startURLChange = (path) => {
      if (window.location.pathname !== path.split('?')[0]) {
        NProgress.start();
      }
    };

    const endURLChange = () => {
      NProgress.done();
      if (window.location.pathname !== currentPath.split('?')[0]) {
        window.scrollTo(0, 0);
        setCurrentPath(router.asPath);
      }
    };

    Router.events.on('routeChangeStart', startURLChange);
    Router.events.on('routeChangeComplete', endURLChange);
    Router.events.on('routeChangeError', endURLChange);

    return () => {
      Router.events.off('routeChangeStart', startURLChange);
      Router.events.off('routeChangeComplete', endURLChange);
      Router.events.off('routeChangeError', endURLChange);
    };
  }, []);

  useEffect(() => {
    analyticsAdapter.initialize();
  }, []);

  return (
    <Providers
      providers={[
        {
          ProviderComponent: ApolloProvider,
          props: { client: apolloClient },
        },
        {
          ProviderComponent: SessionProvider,
          props: { session: pageProps.session },
        },
        {
          ProviderComponent: ConfigProvider,
          props: { theme: antdTheme },
        },
        {
          ProviderComponent: IntercomProvider,
          props: {
            appId: process.env.INTERCOM_APP_ID,
            autoBoot: true,
          },
        },
      ]}
    >
      <AntApp>
        <GlobalStyles />
        {/* <Header /> */}
        <Component key={router.route} router={router} {...pageProps} />
        <Footer />
        <CookieBanner cookieBanner={pageProps.cookieBanner} />
      </AntApp>
    </Providers>
  );
};

export default App;
