import type { ThemeConfig } from 'antd';

import theme from 'styles/theme';

const antdTheme: ThemeConfig = {
  token: {
    borderRadius: 15,
    colorBgBase: theme.colors.white,
    colorError: theme.colors.error,
    colorInfo: theme.colors.primary,
    colorLink: theme.colors.primary,
    colorPrimary: theme.colors.primaryDeep,
    colorSuccess: theme.colors.green,
    colorTextBase: theme.colors.text,
    colorTextDescription: theme.colors.textSecondary,
    colorWarning: theme.colors.accent,
    colorPrimaryActive: theme.colors.primary,
    controlHeight: 38,
    controlHeightLG: 48,
    controlHeightSM: 24,
    fontFamily: 'Aeonik',
    fontSize: 16,
    colorTextHeading: theme.colors.primaryDeep,
  },
  components: {
    Form: {
      labelColor: theme.colors.text,
    },
    Button: {
      colorPrimary: theme.colors.accent,
      primaryColor: theme.colors.primary,
      colorBorder: theme.colors.accent,
      defaultBg: 'transparent',
      defaultHoverBg: theme.colors.accent,
      fontFamily: 'Aeonik',
      fontWeight: 700,
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',
      colorText: theme.colors.accent,
      borderColorDisabled: 'none',
      contentFontSizeSM: 14,
      lineWidth: 1,
      borderRadiusLG: 50,
      borderRadius: 50,
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 15,
    },
    Select: {
      multipleItemBg: theme.colors.primarySofter,
      optionActiveBg: theme.colors.primarySofter,
      optionSelectedBg: theme.colors.primarySofter,
      optionSelectedColor: theme.colors.primaryDeep,
    },
    Statistic: {
      colorTextDescription: theme.colors.text,
    },
    Modal: {
      paddingMD: 0,
      paddingContentHorizontalLG: 0,
      contentBg: theme.colors.primarySoftest,
    },
    Table: {
      headerColor: theme.colors.primaryDeep,
      fontWeightStrong: 300,
    },
    Tooltip: {
      colorBgSpotlight: `${theme.colors.primaryDeeper}d1`,
    },
    Tour: {
      zIndexPopup: 1071,
    },
    Progress: {
      defaultColor: theme.colors.primary,
    },
    Notification: {
      colorInfo: theme.colors.primary,
      fontSize: 14,
    },
    Message: {
      colorInfo: theme.colors.primary,
      fontSize: 14,
    },
  },
};

export default antdTheme;
